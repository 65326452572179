const formSubmit = {
    submitButton: document.querySelector('.footer__submit'),
    emailInput: document.querySelector('.footer__email-input'),
    checkBoxWrap: document.querySelector('.footer__checkbox-wrap'),
    formWrap: document.querySelector('.footer__form-wrap'),
    successMessage: document.querySelector('.footer__success-message'),
    init() {
        if (this.submitButton) {
            this.submitButton.addEventListener('click', (e: Event) => {
                e.preventDefault();
                this.submit();
            });
        }
    },
    // TO DO: Change when it's become clear how we'll handle error messages
    // showErrorMessage() {},
    showSuccessMessage() {
        // set opacity of email, submit and checkbox-wrap to 0
        this.submitButton?.classList.add('footer__submit--transparent');
        this.emailInput?.classList.add('footer__email-input--transparent');
        this.checkBoxWrap?.classList.add('footer__checkbox-wrap--transparent');

        // kick off white bar animation
        this.formWrap?.classList.add('footer__form-wrap--animate');

        // add thanks for subcribing
        this.successMessage?.classList.add('footer__success-message--animate');

        // remove thanks for subscribing after 5 seconds and set opacity of email, submit and checkbox-wrap to 1
        setTimeout(() => {
            // Reset
            this.submitButton?.classList.remove('footer__submit--transparent');
            this.emailInput?.classList.remove(
                'footer__email-input--transparent',
            );
            this.checkBoxWrap?.classList.remove(
                'footer__checkbox-wrap--transparent',
            );
            this.formWrap?.classList.remove('footer__form-wrap--animate');
            this.successMessage?.classList.remove(
                'footer__success-message--animate',
            );
        }, 4700);
    },
    // TO DO: Change when the submit functionality has been implemented in the backend
    submit() {
        // async/await
        const isSend = new Promise((resolve) => {
            setTimeout(() => resolve(true), 2000);
        });

        isSend.then(() => {
            this.showSuccessMessage();
        });
        // .catch(() => {
        // this.showErrorMessage();
        // });
    },
};

export default formSubmit;
