import gsap from 'gsap';
import debounce from '../utils/debounce';
import { isMobile } from '../constants/mediaqueries';

class Card {
    private el: Element;
    private textWrap: HTMLElement | null = null;
    private textEl: HTMLElement | null = null;
    private textWrapHeight = 0;
    private textElHeight = 0;
    private prevWindowWidth: number = window.innerWidth;

    constructor(el: Element) {
        this.el = el;
        if (isMobile()) return;
        this.textWrap = el.querySelector('.card__text-wrap');
        this.textEl = el.querySelector('.card__text');
        if (!this.textWrap || !this.textEl) return;
        this.init();
        this.addListeners();
    }

    private showDescription = () => {
        const tl = gsap.timeline();
        tl.set(this.textWrap, { height: this.textWrapHeight });
        tl.to(this.textWrap, {
            height: this.textWrapHeight + this.textElHeight,
            duration: 0.2,
            ease: 'sine.out()',
        });
        tl.set(this.textEl, {
            position: 'relative',
            visibility: 'visible',
            opacity: 0,
        });
        tl.to(this.textEl, { opacity: 1, duration: 0.3 });
    };

    private hideDescription = () => {
        const tl = gsap.timeline();
        tl.to(this.textEl, { opacity: 0, duration: 0.3 });
        tl.set(this.textEl, {
            position: 'absolute',
            visibility: 'hidden',
            opacity: 0,
        });
        tl.to(this.textWrap, {
            height: this.textWrapHeight,
            duration: 0.2,
            ease: 'sine.out()',
        });
    };

    private resizeHandler = () => {
        /* The resize event is executed either when the menubar disappears/appears or when the searchbar appears/disappears.
         * This check prevents executing the scrollElementsToPosition method which prevents the text image from scrolling.
         */
        if (this.prevWindowWidth !== window.innerWidth) {
            this.prevWindowWidth = window.innerWidth;
            this.init();
        }
    };

    private enter() {
        if (this.el.classList.contains('mouseenter')) return;
        this.el.classList.add('mouseenter');
        this.showDescription();
    }

    private leave() {
        this.el.classList.remove('mouseenter');
        this.hideDescription();
    }

    private addListeners() {
        this.el.addEventListener('mouseenter', () => this.enter());
        this.el.addEventListener('mouseleave', () => this.leave());
        const link = this.el.querySelector('.card__link');
        link?.addEventListener('focus', () => this.enter());
        link?.addEventListener('blur', () => this.leave());

        window.addEventListener('resize', debounce(this.resizeHandler, 200));
    }

    private init() {
        if (!this.textWrap || !this.textEl) return;
        this.textWrapHeight = this.textWrap.offsetHeight;
        this.textElHeight = this.textEl.offsetHeight;
        gsap.set(this.textWrap, { clearProps: 'all' });
        gsap.set(this.textEl, { clearProps: 'all' });
    }
}

export default function initCards() {
    const cards = document.querySelectorAll('[data-id="card"]');

    cards.forEach((card) => {
        /* eslint-disable no-new */
        new Card(card);
    });
}
