function objectFitPolyfill() {
    const objectFitImages = document.querySelectorAll('[data-object-fit]');

    for (let i = 0; i < objectFitImages.length; i++) {
        const image = objectFitImages[i];
        const src = image.getAttribute('data-src') || image.getAttribute('src');
        let parent;
        let target;

        if (image.parentElement?.tagName === 'PICTURE') {
            parent = image.parentElement?.parentElement;
            target = image.parentElement;
        } else {
            parent = image.parentElement;
            target = image;
        }

        parent?.setAttribute(
            'style',
            `background: url("${src}") no-repeat center center/cover`,
        );
        parent?.removeChild(target);
    }
}

export default objectFitPolyfill;
