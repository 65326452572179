/* eslint-disable @typescript-eslint/no-explicit-any */
import { isMobile } from '../constants/mediaqueries';
import isAutoplaySupported from '../utils/features/isAutoplaySupported';
import youtubeVideoBackground from './youtube-video-background';
import youtubeVideoPopUp from './youtube-video-pop-up';
import VideoEventTracking from './youtube-video-tracking';

export type YoutubePlayer = any;
export type YoutubeStateChangeEvent = any;
export type YoutubePlayerError = any;

export const youtubeVideoHost = 'https://www.youtube-nocookie.com';

interface Player {
    el: HTMLElement;
    videoId: string;
    playlist?: string | string[];
    background: boolean;
    onReady?: (event?: any) => void;
    onStateChange?: (event: any) => void;
    title: string;
}

export const createPlayer = ({
    el,
    videoId,
    playlist,
    background,
    onReady,
    onStateChange,
    title = videoId || '',
}: Player) => {
    let player: any;
    let playerVars: any = {
        origin: window.location.origin,
        playsinline: 1,
        rel: 0,
        showinfo: 0,
    };
    let duration = 0;
    const videoEventTracking = new VideoEventTracking();

    if (background) {
        playerVars = {
            autoplay: 0,
            controls: 0,
            loop: 1,
            mute: 1,
            modestbranding: 1,
            origin: window.location.origin,
            playlist,
            playsinline: 1,
            rel: 0,
            showinfo: 0,
        };
    }

    const onPlayerReady = (event: any) => {
        if (onReady) {
            onReady(event);
        }
        duration = player.getDuration();
    };

    const onPlayerStateChange = (event: any) => {
        if (onStateChange) {
            onStateChange(event);
        }

        switch (event.data) {
            case window.YT.PlayerState.ENDED:
                videoEventTracking.trigger(videoId, title, 'complete');
                videoEventTracking.stopProgressTracker();
                break;
            case window.YT.PlayerState.PLAYING:
                videoEventTracking.trigger(videoId, title, 'start');
                videoEventTracking.trackProgress(
                    player,
                    duration,
                    videoId,
                    title,
                );
                break;
            case window.YT.PlayerState.PAUSED:
                videoEventTracking.trigger(videoId, title, 'paused');
                videoEventTracking.stopProgressTracker();
                break;
            default:
                videoEventTracking.stopProgressTracker();
                break;
        }
    };

    const onPlayerError = (error: any) => {
        console.warn('error', error);
    };

    player = new window.YT.Player(el, {
        videoId,
        host: youtubeVideoHost,
        playerVars,
        events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
            onError: onPlayerError,
        },
    });

    return { youtubePlayer: player };
};

export default function youtubeVideo() {
    const backgroundVideoModules = Array.from(
        document.querySelectorAll<HTMLElement>(
            '[data-id="hero"], [data-id="contentFullWidthImage"], [data-hero-slide]',
        ),
    ).filter((hero) => hero.querySelector('[data-video]'));
    const popUpVideoModules = Array.from(
        document.querySelectorAll<HTMLElement>('[data-video-in-popup]'),
    );

    if (!backgroundVideoModules || !popUpVideoModules) return;

    const init = () => {
        // load the IFrame Player API code asynchronously
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        document.head.appendChild(tag);
        // create video modules
        window.onYouTubeIframeAPIReady = () => {
            backgroundVideoModules.forEach((module) =>
                youtubeVideoBackground(module),
            );
            popUpVideoModules.forEach((module) => youtubeVideoPopUp(module));
        };
    };

    if (popUpVideoModules) {
        init();
    } else {
        if (isMobile()) return;
        isAutoplaySupported((supported: boolean) => {
            if (supported) {
                init();
            }
        });
    }
}
