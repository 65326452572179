import { isPhone } from '../constants/mediaqueries';

export default function popUp() {
    const pixel = document.getElementById('pop-up-pixel');
    const popUpEl = document.getElementById('pop-up');
    const popUpBarEl = document.getElementById('pop-up-bar');
    if (!pixel || !popUpEl || !popUpBarEl) return;
    // check for sessionstorage
    const storageKey = 'pop-up';
    const storageData = sessionStorage.getItem(storageKey);
    if (storageData) return;
    // buttons
    const closePopUpButton = popUpEl?.querySelector<HTMLButtonElement>(
        '[data-id="hide-pop-up-bar"]',
    );
    const closePopUpBarButton = popUpBarEl?.querySelector<HTMLButtonElement>(
        '[data-id="hide-pop-up-bar"]',
    );

    const hidePopUp = () => {
        popUpEl.classList.add('pop-up--hide');
        // save
        sessionStorage.setItem(storageKey, 'hidden');
    };

    const hidePopUpBar = () => {
        popUpBarEl.classList.add('pop-up-bar--hide');
    };

    const showPopUp = () => {
        popUpEl.classList.remove('pop-up--hide');
        closePopUpButton?.addEventListener('click', () => {
            hidePopUp();
            hidePopUpBar();
        });
    };

    const showPopUpBar = () => {
        popUpBarEl.classList.remove('pop-up-bar--hide');
        popUpBarEl.addEventListener('click', (e: MouseEvent) => {
            if ((e.target as HTMLElement).nodeName !== 'BUTTON') {
                showPopUp();
                hidePopUpBar();
            }
        });
        closePopUpBarButton?.addEventListener('click', (e: MouseEvent) => {
            if ((e.target as HTMLElement).nodeName === 'BUTTON') {
                hidePopUpBar();
                hidePopUp();
            }
        });
    };

    const config = {
        rootMargin: '0px 0px 0px 0px',
    };

    const onIntersecting = (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver,
    ) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
            const { isIntersecting, target } = entry;
            if (isIntersecting) {
                if (isPhone()) {
                    showPopUpBar();
                } else {
                    showPopUp();
                }
                // unobserve
                observer.unobserve(target);
            }
        });
    };

    const intersectionObserver = new IntersectionObserver(
        onIntersecting,
        config,
    );

    intersectionObserver.observe(pixel);
}
