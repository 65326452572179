const breakpoints = {
    phone: 360,
    tablet: 760,
    laptop: 1024,
    desktop: 1280,
};

export const isPhone = () => window.innerWidth < breakpoints.tablet;
export const isMobile = () => window.innerWidth <= breakpoints.laptop;
export const isDesktop = () => window.innerWidth > breakpoints.laptop;
