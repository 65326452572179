import 'whatwg-fetch';

interface Country {
    IconPath: string;
    Id: number;
    LanguageCode: string;
    LanguageName: string;
    LanguageNodeGuid: string;
    LastModified: string;
    MarketCode: string;
    MarketName: string;
    Region: string;
}

interface RegionsEntity {
    Global?: Country[];
    Europe?: Country[];
    Africa?: Country[];
    Americas?: Country[];
    ['Asia Pacific']?: Country[];
    ['Middle East']?: Country[];
}

function createRegion(region: string, countries: Country[]) {
    const regionEl = document.querySelector(`#region-${region}`);

    const countryList = document.createElement('UL');
    countryList.setAttribute('class', 'country-selector__markets-list');

    countries.forEach((country) => {
        const listEl = document.createElement('LI');
        listEl.setAttribute('class', 'country-selector__market');

        const linkEl = document.createElement('A');
        linkEl.setAttribute(
            'href',
            `/${country.MarketCode}/${country.LanguageCode}`,
        );
        linkEl.setAttribute('class', 'country-selector__market-link');

        let imgEl;
        if (country.IconPath) {
            imgEl = document.createElement('IMG');
            imgEl.setAttribute('class', 'country-selector__market-image');
            imgEl.setAttribute('src', country.IconPath);
            imgEl.setAttribute('alt', country.MarketCode);
            imgEl.setAttribute('loading', 'lazy');
            imgEl.setAttribute('width', '24');
            imgEl.setAttribute('height', '24');
        }

        const marketTextEl = document.createElement('SPAN');
        marketTextEl.setAttribute('class', 'country-selector__market-text');

        const marketNameEl = document.createElement('SPAN');
        marketNameEl.setAttribute('class', 'country-selector__market-name');
        marketNameEl.innerHTML = country.MarketName;

        const marketLangEl = document.createElement('SPAN');
        marketLangEl.setAttribute('class', 'country-selector__market-language');
        marketLangEl.innerHTML = country.LanguageName;

        marketTextEl.appendChild(marketNameEl);
        marketTextEl.appendChild(marketLangEl);

        if (imgEl) {
            linkEl.appendChild(imgEl);
        }
        linkEl.appendChild(marketTextEl);

        listEl.appendChild(linkEl);

        countryList?.appendChild(listEl);
    });

    regionEl?.appendChild(countryList);
}

function addCountriesToDocument(regions: RegionsEntity) {
    for (const [key, value] of Object.entries(regions)) {
        // transform data
        const region = key.replace(' ', '');
        const countries = value.sort((a: Country, b: Country) => {
            if (a.MarketName < b.MarketName) {
                return -1;
            }
            if (a.MarketName > b.MarketName) {
                return 1;
            }
            return 0;
        });

        createRegion(region, countries);
    }
}

async function getCountries() {
    await fetch('/Umbraco/Api/CountrySelector/GetAvailableCountries').then(
        (response) => {
            response.json().then((data) => {
                addCountriesToDocument(data);
            });
        },
    );
}

export default getCountries;
