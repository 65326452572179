import 'whatwg-fetch';
import modal from './modal';

// It has been decided to host this key client side for now.
// This will be implemented in BE later.
const HEINEKEN_RAAS_KEY = '66e874c31dc9456da19dbe62f676db63';
const RAAS_API_URL = 'https://logicapi.heineken.nl/raas/api/Folder';

interface Deeplink {
    id: string;
    name: string;
    image: string;
    url: string;
}

interface SKU {
    name: string;
    id: number;
    deeplinks: Deeplink[];
}

async function getReferrals(id: string) {
    const data = await fetch(`${RAAS_API_URL}/${id}`, {
        headers: {
            'Ocp-Apim-Subscription-Key': HEINEKEN_RAAS_KEY,
        },
    })
        .then((response) => {
            const { status, statusText } = response;
            if (status === 200) {
                return response.json();
            }
            return statusText;
        })
        .catch((ex) => {
            return JSON.stringify(ex);
        });

    return data;
}

function createReferral(deeplink: Deeplink) {
    if (!deeplink) return null;
    const { name, image, url } = deeplink;
    return `
        <li class="raas__list-item">
            <a href="${url}" class="raas__referral-link">
                <img src="${image}" class="raas__referral-image" alt="${name}" />
                <span class="raas__referral-title">${name}</span>
            </a>
        </li>
    `;
}

function createReferralsTemplate(data: SKU) {
    const { name, deeplinks } = data;
    return `
        <h1 class="raas__title">${name}</h1>
        <ul class="raas__list">
            ${deeplinks
                .map((deeplink: Deeplink) => createReferral(deeplink))
                .join('')}
        </ul>
    `;
}

function createErrorTemplate(data: string, productId: string) {
    return `
        <h1>${data}</h1>
        <p>Product: ${productId}</p>
    `;
}

function eachButton(button: HTMLButtonElement) {
    const id = button.getAttribute('data-raas-sku-id');
    if (!id) return;
    const clickHandler = async () => {
        const data = await getReferrals(id);
        let modalContent = '';
        if (data && data.name) {
            modalContent = createReferralsTemplate(data);
        } else {
            modalContent = createErrorTemplate(data, id);
        }
        modal.open(modalContent);
    };
    button.addEventListener('click', clickHandler);
}

const raasButton = () => {
    const buttons = document.querySelectorAll<HTMLButtonElement>(
        '[data-raas-sku-id]',
    );

    buttons.forEach((button) => eachButton(button));
};

export default raasButton;
