/* eslint-disable @typescript-eslint/ban-ts-ignore, no-new */
// import Xwiper from '@src/scripts/utils/xwiper';
import scrollTo from '@src/scripts/utils/smoothscroll';
// import supportsPassive from '@src/scripts/utils/features/supportsPassive';
// import isTouchDevice from '@src/scripts/utils/features/isTouchDevice';
import { isDesktop, isMobile } from '@src/scripts/constants/mediaqueries';
import debounce from '@src/scripts/utils/debounce';

class ProductHero {
    parent: Element;
    prevButton: Element | null;
    nextButton: Element | null;
    enterButton: Element | null;
    prevSlide: Element | null;
    nextSlide: Element | null;
    currentSlide: Element | null;
    nextArrow: Element | null;
    prevArrow: Element | null;
    image: Element | null;
    content: Element[] | null;

    constructor(productHero: Element) {
        this.parent = productHero;
        this.prevButton = productHero.querySelector('[data-button-prev]');
        this.nextButton = productHero.querySelector('[data-button-next]');
        this.enterButton = productHero.querySelector('[data-button-enter]');
        this.prevSlide = productHero.querySelector('[data-slide-prev]');
        this.nextSlide = productHero.querySelector('[data-slide-next]');
        this.currentSlide = productHero.querySelector('[data-slide-current]');
        this.nextArrow = this.parent.querySelector(
            '[data-button-next] .product-hero__arrow-wrap',
        );
        this.prevArrow = this.parent.querySelector(
            '[data-button-prev] .product-hero__arrow-wrap',
        );
        this.image = this.parent.querySelector('[data-slide-image]');
        this.content = Array.from(
            this.parent.querySelectorAll('[data-id="content-container"] > *'),
        );
        this.init();
    }

    init() {
        if (this.prevButton?.hasAttribute('data-href') && this.prevSlide) {
            this.addButtonListeners(this.prevButton, this.prevSlide, false);
            this.prevButton.classList.remove('hide');
        }

        if (this.nextButton?.hasAttribute('data-href') && this.nextSlide) {
            this.addButtonListeners(this.nextButton, this.nextSlide);
            this.nextButton.classList.remove('hide');
        }

        this.animateContentInView();
        this.animateImageInView();

        this.enterButton?.addEventListener('click', (e: Event) =>
            this.scrollDown(e),
        );
        window.addEventListener(
            'resize',
            debounce(() => this.handleResize(), 200),
        );
    }

    addButtonListeners(button: Element, targetSlide: Element, isNext = true) {
        const link = button.getAttribute('data-href');

        if (!link) return;

        button.addEventListener('click', (e: Event) => {
            e.preventDefault();
            this.goToProduct(targetSlide, link, isNext);
        });
        button.addEventListener('mouseenter', () =>
            this.showPreview(targetSlide, isNext),
        );
        button.addEventListener('focus', () =>
            this.showPreview(targetSlide, isNext),
        );
        button.addEventListener('mouseleave', () =>
            this.hidePreview(targetSlide, isNext),
        );
        button.addEventListener('blur', () =>
            this.hidePreview(targetSlide, isNext),
        );

        // disable swipe events because it's impossible to scroll down without navigating to another product
        // if (isTouchDevice()) {
        //    const xwiper = new Xwiper(
        //        this.image,
        //        supportsPassive() ? { passive: true } : false,
        //    );

        //    if (isNext) {
        //        xwiper.onSwipeLeft(() =>
        //            this.goToProduct(targetSlide, link, isNext),
        //        );
        //    } else {
        //        xwiper.onSwipeRight(() =>
        //            this.goToProduct(targetSlide, link, isNext),
        //        );
        //    }
        // }
    }

    handleResize() {
        if (isDesktop()) {
            this.animateImageInView();
            this.animateContentInView();
        }
    }

    animateImageInView() {
        this.image?.setAttribute(
            'style',
            'transform: translate3d(0, 0, 0); opacity: 1',
        );
    }

    animateContentInView() {
        this.content?.forEach((contentEl, i) =>
            contentEl?.setAttribute(
                'style',
                `transform: translate3d(0, 0, 0); transition-delay: ${
                    0.8 + i / 10
                }s; opacity: 1`,
            ),
        );
    }

    goToProduct(slide: Element, link: string, isNext = true) {
        if (!this.currentSlide) return;

        this.nextArrow?.classList.add('full-shift');
        this.prevArrow?.classList.add('full-shift');
        slide.classList.add('show');

        if (isNext) this.currentSlide.classList.add('full-shift--left');
        else this.currentSlide.classList.add('full-shift--right');

        // Go to link after animation
        const delay = isMobile() ? 400 : 800;
        setTimeout(() => {
            window.location.pathname = link;
        }, delay);
    }

    scrollDown(e: Event) {
        e.preventDefault();

        if (isMobile()) {
            const scrollHeight = this.parent.querySelector(
                '.product-hero__background',
                // @ts-ignore offsetHeight does exist on a HTMLElement
            )?.offsetHeight;
            scrollTo(
                document.scrollingElement || document.documentElement,
                0,
                scrollHeight,
            );
        } else {
            scrollTo(
                document.scrollingElement || document.documentElement,
                0,
                window.innerHeight,
            );
        }
    }

    showPreview(slide: Element, isNext = true) {
        if (!this.currentSlide) return;
        slide.classList.add('preview');

        if (isNext) {
            this.currentSlide.classList.add('shift--left');
            this.nextArrow?.classList.add('shift');
        } else {
            this.currentSlide.classList.add('shift--right');
            this.prevArrow?.classList.add('shift');
        }
    }

    hidePreview(slide: Element, isNext = true) {
        if (!this.currentSlide) return;

        slide.classList.remove('preview');
        if (isNext) {
            this.currentSlide.classList.remove('shift--left');
            this.nextArrow?.classList.remove('shift');
        } else {
            this.currentSlide.classList.remove('shift--right');
            this.prevArrow?.classList.remove('shift');
        }
    }
}

export default function createProductHeroes() {
    const productHeroes = Array.from(
        document.querySelectorAll('[data-product-hero]'),
    );
    productHeroes.forEach((productHero) => new ProductHero(productHero));
}
