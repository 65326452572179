/* eslint-disable @typescript-eslint/ban-ts-ignore */
import copyStringToClipboard from '../utils/copyStringToClipboard';

type HTMLElementEvent<T extends HTMLElement> = Event & {
    currentTarget: T;
};

export default function socialShare() {
    const url = window.location.href;
    const modules = document.querySelectorAll('[data-id="social-share"]');

    const facebookShare = () => {
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${url}`,
            'facebook-share-dialog',
            'width=800,height=600',
        );
        return false;
    };

    const twitterShare = () => {
        window.open(
            `https://twitter.com/intent/tweet?url=${url}`,
            'twitter-share-dialog',
            'width=800,height=600',
        );
        return false;
    };

    const linkedinShare = () => {
        window.open(
            `https://www.linkedin.com/shareArticle?url=${url}`,
            'linkedin-share-dialog',
            'width=800,height=600',
        );
        return false;
    };

    const linkShare = (e: HTMLElementEvent<HTMLUListElement>) => {
        const { currentTarget } = e;
        currentTarget?.classList.remove('copied');
        copyStringToClipboard(url);
        currentTarget?.classList.add('copied');
        setTimeout(() => currentTarget?.classList.remove('copied'), 3000);
    };

    const init = (module: Element) => {
        const facebook = module.querySelector('[data-id="share-facebook"]');
        facebook?.addEventListener('click', facebookShare);

        const twitter = module.querySelector('[data-id="share-twitter"]');
        twitter?.addEventListener('click', twitterShare);

        const linkedin = module.querySelector('[data-id="share-linkedin"]');
        linkedin?.addEventListener('click', linkedinShare);

        const link = module.querySelector('[data-id="share-link"]');
        // @ts-ignore the right event
        link?.addEventListener('click', linkShare);
    };

    modules.forEach((module) => init(module));
}
