import isNumberKey from '../utils/isNumberKey';
import isTextSelected from '../utils/isTextSelected';

export default function ageGateway() {
    const formWrap = document.querySelector('#age-gateway-form-wrap');
    if (!formWrap) return;

    const inputs: HTMLInputElement[] = Array.from(
        formWrap.querySelectorAll('input'),
    );
    const submit: HTMLButtonElement | null = document.querySelector(
        '#age-gateway-submit',
    );
    const disable = () => {
        if (submit) {
            // Disable submit button
            submit.disabled = true;
        }
    };
    const enable = () => {
        if (submit) {
            // Enable submit button
            submit.disabled = false;
        }
    };

    if (!inputs || inputs.length <= 0) return;

    const [inputDay, inputMonth, inputYear] = inputs;

    // Focus on first input field
    inputDay.focus();

    let daysValid: boolean;
    let monthValid: boolean;
    let yearValid: boolean;

    const validate = () => {
        if (inputDay.value && inputDay.value.length === 2) {
            if (parseInt(inputDay.value, 10) <= 31) {
                daysValid = true;
                inputMonth.focus();
            } else {
                inputDay.value = '';
            }
        } else {
            daysValid = false;
        }
        if (daysValid && inputMonth.value && inputMonth.value.length === 2) {
            if (parseInt(inputMonth.value, 10) <= 12) {
                monthValid = true;
                inputYear.focus();
            } else {
                inputMonth.value = '';
            }
        } else {
            monthValid = false;
        }
        if (
            daysValid &&
            monthValid &&
            inputYear.value &&
            inputYear.value.length === 4
        ) {
            yearValid = true;
        } else {
            yearValid = false;
        }
        if (daysValid && monthValid && yearValid) {
            enable();
            submit?.focus();
        } else {
            disable();
        }
    };

    const inputKeypressHandler = (
        event: KeyboardEvent,
        input: HTMLInputElement,
    ) => {
        if (!isNumberKey(event)) {
            event.preventDefault();
        }
        if (!isTextSelected(input)) {
            if (input === inputDay && input.value.length >= 2) {
                event.preventDefault();
            }
            if (input === inputMonth && input.value.length >= 2) {
                event.preventDefault();
            }
            if (input === inputYear && input.value.length >= 4) {
                event.preventDefault();
            }
        }
    };

    inputs.forEach((input) => {
        input.addEventListener('keypress', (event) => {
            inputKeypressHandler(event, input);
        });

        input.addEventListener('keyup', () => validate());
    });
}
