import { YoutubePlayer } from './youtube-video';

class VideoEventTracking {
    progressInterval = 0;
    watchedQuarter = false;
    watchedHalf = false;
    watchedThreeQuarters = false;
    watchedFully = false;

    trigger(
        videoId: string,
        videoTitle: string,
        action: 'start' | 'complete' | 'paused' | '25' | '50' | '75' | '100',
    ) {
        if (!window.digitalData) return;

        const dataObj = {
            category: 'video', // fixed value
            action,
            label: videoId, // the Youtube video id
            videoTitle, // title of the video
        };

        window.digitalData.events = dataObj;

        try {
            window.Bootstrapper.ensEvent.trigger('video');
        } catch (err) {
            console.error(
                `Couldn't reach Bootstrapper to trigger 'video' with ${JSON.stringify(
                    dataObj,
                )}`,
            );
        }
    }

    stopProgressTracker() {
        window.clearInterval(this.progressInterval);
    }

    clearValues() {
        this.watchedQuarter = false;
        this.watchedHalf = false;
        this.watchedFully = false;
        this.watchedFully = false;
    }

    trackProgress(
        player: YoutubePlayer,
        duration: number,
        videoId: string,
        title: string,
    ) {
        if (!duration) return;
        this.progressInterval = window.setInterval(() => {
            const progress = player.getCurrentTime();
            const percentage = Math.ceil((progress / duration) * 100) / 100;
            if (!this.watchedQuarter && percentage >= 0.25) {
                this.trigger(videoId, title, '25');
                this.watchedQuarter = true;
            }
            if (!this.watchedHalf && percentage >= 0.5) {
                this.trigger(videoId, title, '50');
                this.watchedHalf = true;
            }
            if (!this.watchedThreeQuarters && percentage >= 0.75) {
                this.trigger(videoId, title, '75');
                this.watchedThreeQuarters = true;
            }
            if (!this.watchedFully && percentage >= 1) {
                this.trigger(videoId, title, '100');
                this.watchedFully = true;
                this.clearValues();
            }
        }, 1000);
    }
}

export default VideoEventTracking;
