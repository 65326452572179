export default function promoBar() {
    const el = document.getElementById('promo-bar');
    if (!el) return;

    // check session storage
    const storageData = sessionStorage.getItem('promo-bar');
    if (storageData) return;

    // show
    el.classList.remove('promo-bar--is-hidden');
    setTimeout(() => {
        el.classList.remove('promo-bar--hide');
    }, 1500);

    const collapseButton = document.getElementById('collapse-promo-bar');
    const hideButton = document.getElementById('hide-promo-bar');
    const toggleContent = () => {
        const className = 'promo-bar--show-content';
        if (el.classList.contains(className)) {
            el.classList.remove(className);
        } else {
            el.classList.add(className);
        }
    };
    const hidePromoBar = () => {
        el.classList.add('promo-bar--hide');
        setTimeout(() => {
            el.classList.add('promo-bar--is-hidden');
        }, 800);
        // save to session stage
        sessionStorage.setItem('promo-bar', 'hidden');
    };

    // listeners
    collapseButton && collapseButton.addEventListener('click', toggleContent);
    hideButton && hideButton.addEventListener('click', hidePromoBar);
}
