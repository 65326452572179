import { isMobile } from '../constants/mediaqueries';

const slideInOut = {
    sliders: document.querySelectorAll('[data-slide]'),
    init() {
        for (let i = 0; i < this.sliders.length; i++) {
            const slider = this.sliders[i];
            const button = slider.querySelector('[data-slide-button]');
            const container = this.sliders[i].querySelector(
                '[data-slide-container]',
            );

            if (!button || !container) return;

            button.addEventListener('click', () => {
                this.toggleNutritions(slider);
            });

            if (isMobile()) {
                this.closeNutritions(button, container);
            }
        }
    },
    handleResize() {
        for (let i = 0; i < this.sliders.length; i++) {
            const button = this.sliders[i].querySelector('[data-slide-button]');
            const container = this.sliders[i].querySelector(
                '[data-slide-container]',
            );

            if (!button || !container) return;

            if (isMobile()) {
                this.closeNutritions(button, container);
            } else {
                container.removeAttribute('style');
                button.classList.remove('open');
                container.classList.remove('open');
            }
        }
    },
    toggleNutritions(slider: Element) {
        const button = slider.querySelector('[data-slide-button]');
        const slideContainer = slider.querySelector('[data-slide-container]');

        if (slideContainer && button) {
            if (button.className.indexOf('open') !== -1) {
                this.closeNutritions(button, slideContainer);
            } else {
                this.openNutritions(button, slideContainer);
            }
        }
    },
    closeNutritions(button: Element, slideContainer: Element) {
        button.classList.remove('open');
        slideContainer.classList.remove('open');
        slideContainer.setAttribute('style', 'height: 0');
    },
    openNutritions(button: Element, slideContainer: Element) {
        const height = slideContainer.scrollHeight;
        button.classList.add('open');
        slideContainer.classList.add('open');
        slideContainer.setAttribute('style', `height: ${height}px`);
    },
};

export default slideInOut;
