import { createPlayer, YoutubePlayer } from './youtube-video';

const youtubeVideoPopUp = (module: HTMLElement) => {
    const popup = module.querySelector('[data-popup]');
    if (!popup) return;
    const video = popup.querySelector<HTMLElement>('[data-popup-video]');
    if (!video) return;
    let player: YoutubePlayer;
    const playButtons = module.querySelectorAll('[data-play-button]');
    const closeButton = module.querySelector('[data-close]');
    const overlays = Array.from(popup.querySelectorAll('[data-overlay]'));
    const iframeContainer = popup.querySelector('[data-video-container]');
    const videoId = video.id;
    const title = module.querySelector('H1')?.innerHTML || '';

    const open = () => {
        module.classList.add('media--active');
        document.body.classList.add('lock-scroll');
        popup.classList.remove('hidden');
        iframeContainer?.classList.remove('hidden');
        overlays.forEach((overlay) => {
            overlay.classList.add('show');
        });

        setTimeout(() => {
            player.playVideo();
        }, 300);
    };

    const close = () => {
        player.pauseVideo();
        module.classList.remove('media--active');
        document.body.classList.remove('lock-scroll');
        popup.classList.add('hidden');
        iframeContainer?.classList.add('hidden');
        overlays.forEach((overlay) => {
            overlay.classList.remove('show');
        });
    };

    const handleClick = (bool: boolean) => {
        if (bool) {
            open();
        } else {
            close();
        }
    };

    const { youtubePlayer } = createPlayer({
        el: video,
        videoId,
        background: false,
        title,
    });
    player = youtubePlayer;

    playButtons.forEach((button) => {
        button.addEventListener('click', () => handleClick(true));
    });
    closeButton?.addEventListener('click', () => handleClick(false));
};

export default youtubeVideoPopUp;
