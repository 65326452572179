import gsap from 'gsap';
import Xwiper from '../utils/xwiper';
import supportsPassive from '../utils/features/supportsPassive';
import isTouchDevice from '../utils/features/isTouchDevice';
import CarouselButtons from './carousel-buttons';
import debounce from '../utils/debounce';

export interface CarouselOptions {
    containerEl: HTMLElement;
    scrollIndicator?: boolean;
    autoplay?: boolean;
}

class Carousel extends CarouselButtons {
    private carousel: HTMLElement;
    private list: HTMLElement | null = null;
    private listItems: NodeListOf<HTMLElement> | null = null;
    private listItemsLength = 0;
    private listItemWidth = 0;
    public numItemsToScroll = 2;
    private scrollIndicator = false;
    private scrollDistance = 100;
    private listPositionX = 0;
    private currentListItemIndex = 0;
    private prevWindowWidth: number = window.innerWidth;

    constructor(carouselOptions: CarouselOptions) {
        super(carouselOptions);
        const { containerEl, scrollIndicator } = carouselOptions;

        this.carousel = containerEl;
        if (!this.carousel) return;

        if (scrollIndicator) {
            this.scrollIndicator = true;
        }

        const list: HTMLElement | null = this.carousel.querySelector(
            '[data-id="carousel-list"]',
        );
        if (!list) return;

        const listItems: NodeListOf<HTMLElement> | null = list.querySelectorAll(
            'li',
        );
        if (!listItems) return;

        this.list = list;
        this.listItems = listItems;
        this.listItemsLength = listItems.length;

        this.init();
    }

    private animate(distance: number) {
        if (!this.list) return;

        gsap.to(this.list, {
            x: distance,
            duration: 0.4,
            ease: 'power2.out',
            onComplete: () => {
                this.updateButtonsState();
                this.updateIndicatorState();
            },
        });
    }

    private next() {
        if (this.currentListItemIndex >= this.listItemsLength - 1) return;
        this.currentListItemIndex += this.numItemsToScroll;
        this.listPositionX -= this.scrollDistance;
        this.animate(this.listPositionX);
    }

    private prev() {
        if (this.currentListItemIndex <= 0) return;
        this.currentListItemIndex -= this.numItemsToScroll;
        this.listPositionX += this.scrollDistance;
        this.animate(this.listPositionX);
    }

    private getCarouselWidth(): number {
        return this.listItemWidth * this.listItemsLength;
    }

    private setListWidth() {
        if (!this.list) return;
        const width = this.getCarouselWidth();
        this.list.style.width = `${width}px`;
    }

    private getListItemWidth(): number {
        if (!this.listItems) return 0;
        // base width on first item in list assuming all items in list are from the same content type
        const item = this.listItems[0];
        if (!item) return 0;
        return item.offsetWidth;
    }

    private correctListPosition() {
        const container = this.carousel.querySelector(
            '[data-id="carousel-container"]',
        );
        if (!container) return;
        const { left } = container.getBoundingClientRect();
        this.listPositionX = left;
        this.animate(left);
    }

    private createScrollIndicator() {
        if (!this.scrollIndicator || !this.list) return;
        const { width } = this.list.getBoundingClientRect();
        if (width <= window.innerWidth) return;

        const indicatorsLength = Math.ceil(
            this.listItemsLength / this.numItemsToScroll,
        );

        if (indicatorsLength <= 1) return;

        const scrollIndicatorEl = this.carousel.querySelector(
            '[data-id="scroll-indicator"]',
        );
        if (!scrollIndicatorEl) return;

        // empty children
        scrollIndicatorEl.textContent = '';

        // create ul
        const ul = document.createElement('ul');
        ul.className = 'carousel-indicator__ul';
        scrollIndicatorEl.appendChild(ul);

        // create li's
        for (let i = 0; i < indicatorsLength; i++) {
            const li = document.createElement('li');
            li.className = 'carousel-indicator__li';
            if (i === 0) {
                li.classList.add('is-selected');
            }
            ul.appendChild(li);
        }
    }

    private setSizes() {
        if (!this.list) return;
        this.currentListItemIndex = 0;
        this.listItemWidth = this.getListItemWidth();

        // set num of items to scroll based on max visible items in viewport
        const { left } = this.list.getBoundingClientRect();
        const viewport = window.innerWidth - left * 2;
        this.numItemsToScroll = Math.floor(viewport / this.listItemWidth) || 1;

        // scroll distance based on width of list items that are visible in viewport at the same time
        this.scrollDistance = this.listItemWidth * this.numItemsToScroll;

        this.setListWidth();
        this.correctListPosition();
        this.createScrollIndicator();
    }

    private updateIndicatorState() {
        if (!this.scrollIndicator) return;
        const scrollIndicatorEl = this.carousel.querySelector(
            '[data-id="scroll-indicator"]',
        );
        if (!scrollIndicatorEl) return;
        const listItems = scrollIndicatorEl.querySelectorAll(
            '.carousel-indicator__li',
        );
        if (!listItems || listItems.length <= 0) return;
        const selectedListItem = scrollIndicatorEl.querySelector(
            '.is-selected',
        );
        selectedListItem && selectedListItem.classList.remove('is-selected');
        const factor = this.listItemsLength / listItems.length;
        const index = Math.floor(this.currentListItemIndex / factor);
        listItems[index].classList.add('is-selected');
    }

    public updateButtonsState() {
        if (!this.list) return;

        const { right } = this.list.getBoundingClientRect();
        const outOfViewportLeft = this.listPositionX < 0;
        const outOfViewPortRight = right > window.innerWidth;

        super.showPrevButton(outOfViewportLeft);
        super.showNextButton(outOfViewPortRight);
    }

    public buttonPrevMouseClick() {
        super.buttonPrevMouseClick();
        this.prev();
    }

    public buttonNextMouseClick() {
        super.buttonNextMouseClick();
        this.next();
    }

    private resizeHandler = () => {
        /* The resize event is executed either when the menubar disappears/appears or when the searchbar appears/disappears.
         * This check prevents executing the scrollElementsToPosition method which prevents the text image from scrolling.
         */
        if (this.prevWindowWidth !== window.innerWidth) {
            this.prevWindowWidth = window.innerWidth;
            this.setSizes();
        }
    };

    private addListeners() {
        window.addEventListener('resize', debounce(this.resizeHandler, 200));

        if (isTouchDevice()) {
            // touch gestures
            const xwiper = new Xwiper(
                this.carousel,
                supportsPassive() ? { passive: true } : false,
            );
            xwiper.onSwipeLeft(() => this.next());
            xwiper.onSwipeRight(() => this.prev());
        }
    }

    private init() {
        this.setSizes();
        this.addListeners();
    }
}

export default function createCarousels() {
    const productCarousels: HTMLElement[] = Array.from(
        document.querySelectorAll('[data-id="carousel-product"]'),
    );
    const contentCarousels: HTMLElement[] = Array.from(
        document.querySelectorAll('[data-id="carousel-content"]'),
    );
    productCarousels.forEach(
        (carousel: HTMLElement) =>
            new Carousel({
                containerEl: carousel,
            }),
    );
    contentCarousels.forEach(
        (carousel: HTMLElement) =>
            new Carousel({
                containerEl: carousel,
                scrollIndicator: true,
            }),
    );
}
