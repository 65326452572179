import Xwiper from '../utils/xwiper';
import supportsPassive from '../utils/features/supportsPassive';
import isTouchDevice from '../utils/features/isTouchDevice';
import { STATE } from '../index';

const navigation = {
    html: document.getElementsByTagName('html')[0],
    body: document.getElementsByTagName('BODY')[0],
    header: document.querySelector('[data-id="header"]'),
    button: document.querySelector('[data-id="main-nav-button"]'),
    menuBg: document.querySelector('[data-id="main-nav-mobile-bg"]'),
    menu: document.querySelector('[data-id="main-nav-mobile"]'),
    prevScrollpos: window.pageYOffset,
    init() {
        this.button &&
            this.button.addEventListener('click', () => this.toggleMenu());
        this.menuBg &&
            this.menuBg.addEventListener('click', () => this.toggleMenu());

        if (this.menu && isTouchDevice()) {
            // touch gestures
            const xwiper = new Xwiper(
                this.menu,
                supportsPassive() ? { passive: true } : false,
            );
            xwiper.onSwipeDown(() => this.toggleMenu());
            xwiper.onSwipeRight(() => this.toggleMenu());
        }
        this.toggleOffset();
    },
    toggleMenu() {
        if (this.button && this.button.classList.contains('is-active')) {
            this.button.classList.remove('is-active');
            this.menu && this.menu.classList.remove('is-active');
            this.body.classList.remove('lock-scroll');
        } else {
            this.button && this.button.classList.add('is-active');
            this.menu && this.menu.classList.add('is-active');
            this.body.classList.add('lock-scroll');
        }
    },
    toggleHeader() {
        const currentScrollPos = window.pageYOffset;
        if (this.prevScrollpos > currentScrollPos || currentScrollPos <= 0) {
            // show
            this.header && this.header.classList.remove('header--hide');
            this.button &&
                this.button.classList.remove('main-nav-button--hide');
        } else {
            // hide
            this.header && this.header.classList.add('header--hide');
            this.button && this.button.classList.add('main-nav-button--hide');
        }
        this.prevScrollpos = currentScrollPos;
    },
    toggleOffset() {
        const currentScrollPos = window.pageYOffset;
        if (STATE.hasNotificationBar) {
            if (currentScrollPos <= 1) {
                this.addOffset();
            } else {
                this.removeOffset();
            }
        }
    },
    addOffset() {
        this.html.classList.add('has-notification-bar');
    },
    removeOffset() {
        this.html.classList.remove('has-notification-bar');
    },
};

export default navigation;
