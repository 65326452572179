import isIE from './features/isIE';
import isSafari from './features/isSafari';
import isTouchDevice from './features/isTouchDevice';

export default function featureDetection() {
    const htmlEl = document.getElementsByTagName('html')[0];
    if (isIE()) {
        htmlEl.classList.add('is-ie');
    }
    if (isTouchDevice()) {
        htmlEl.classList.add('is-touch-device');
    }
    if (!window.IntersectionObserver) {
        htmlEl.classList.add('no-intersection-observer');
    }
    if (isSafari()) {
        htmlEl.classList.add('is-safari');
    }
}
