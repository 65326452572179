import { STATE } from '../index';
import navigation from './navigation';

export default function notificationBar() {
    const el = document.getElementById('notification-bar');
    if (!el) return;
    const storageKey = 'notification-bar';
    const storageData = sessionStorage.getItem(storageKey);
    if (storageData) return;
    // show notification bar
    el.classList.add('notification-bar--show');
    // update state
    STATE.hasNotificationBar = true;

    const hideButton = document.getElementById('hide-notification-bar');
    const hideButtonHandler = () => {
        // hide notification bar
        el.classList.remove('notification-bar--show');
        // update state
        STATE.hasNotificationBar = false;
        // save
        sessionStorage.setItem(storageKey, 'hidden');
        // remove offset
        navigation.removeOffset();
    };
    hideButton && hideButton.addEventListener('click', hideButtonHandler);
}
