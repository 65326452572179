interface Modal {
    el: HTMLElement | null;
    content: HTMLElement | null;
    closeButton: HTMLElement | null;
    background: HTMLElement | null;
    close: () => void;
    open: (content: string) => void;
}

const modal: Modal = {
    el: document.getElementById('modal'),
    content: document.getElementById('modal-content'),
    closeButton: document.getElementById('modal-close-button'),
    background: document.getElementById('modal-background'),
    close() {
        // remove content from modal
        if (this.content) {
            this.content.innerHTML = '';
        }
        // enable scroll on body
        document.body.classList.remove('lock-scroll');
        // hide with animation by classname
        this.el?.classList.remove('modal--show');
        // remove listeners
        this.closeButton?.removeEventListener('click', this.close.bind(this));
        this.background?.removeEventListener('click', this.close.bind(this));
    },
    open(content: string) {
        // add content to modal
        if (this.content) {
            this.content.innerHTML = content;
        }
        // disable scroll on body
        document.body.classList.add('lock-scroll');
        // show with animation by classname
        this.el?.classList.add('modal--show');
        // add listeners
        this.closeButton?.addEventListener('click', this.close.bind(this));
        this.background?.addEventListener('click', this.close.bind(this));
    },
};

export default modal;
