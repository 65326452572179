/* eslint-disable consistent-return, func-names */
/* eslint-disable @typescript-eslint/ban-ts-ignore, no-new */
import objectFitPolyfill from './polyfills/objectfitpolyfill';

// forEach
(function () {
    if (typeof NodeList.prototype.forEach === 'function') return false;
    // @ts-ignore: Mandatory to make the fallback work
    NodeList.prototype.forEach = Array.prototype.forEach;
})();

// Object-fit
if ('objectFit' in document.documentElement.style === false) {
    objectFitPolyfill();
}
