/* eslint-disable no-param-reassign */
const supportsNativeSmoothScroll =
    'scrollBehavior' in document.documentElement.style;

// Code from https://nicegist.github.io/d210786daa23fd57db59634dd231f341
function smoothScroll(element: Element, to: number, bottom: boolean) {
    const start = bottom ? element.scrollTop : element.scrollLeft;
    const change = to - start;
    const startDate = +new Date();
    const duration = 500;

    const easeInOut = (t: number, b: number, c: number, d: number) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;

        if (bottom)
            element.scrollTop = easeInOut(currentTime, start, change, duration);
        else
            element.scrollLeft = easeInOut(
                currentTime,
                start,
                change,
                duration,
            );

        if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
        } else if (bottom) element.scrollTop = to;
        else element.scrollLeft = to;
    };
    animateScroll();
}

export default function scrollTo(
    element: Element,
    x: number,
    y: number,
    bottom = true,
) {
    if (supportsNativeSmoothScroll) element.scrollTo(x, y);
    else smoothScroll(element, bottom ? y : x, bottom);
}
