const inView = {
    elements: Array.from(document.querySelectorAll('[data-in-view]')).concat(
        Array.from(document.querySelectorAll('[data-container-in-view] > *')),
    ),
    init() {
        if (window.IntersectionObserver) {
            this.load();
        } else {
            this.showByDefault();
        }
    },
    load() {
        const config = {
            rootMargin: '0px 0px 50px 0px',
        };
        const onIntersecting = (
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
        ) => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                const { isIntersecting, target } = entry;
                if (isIntersecting) {
                    this.setInView(target);
                    observer.unobserve(target);
                }
            });
        };
        const intersectionObserver = new IntersectionObserver(
            onIntersecting,
            config,
        );
        this.elements.forEach((el: Element) => {
            intersectionObserver.observe(el);
        });
    },
    setInView(entry: Element) {
        entry.classList.add('in-view');
    },
    showByDefault() {
        for (let i = 0; i < this.elements.length; i++) {
            this.setInView(this.elements[i]);
        }
    },
};

export default inView;
