// @ts-nocheck
/* eslint-disable getter-return, no-empty */
export default function supportsPassive() {
    let support = false;
    try {
        const opts = Object.defineProperty({}, 'passive', {
            get() {
                support = true;
            },
        });
        window.addEventListener('testPassive', null, opts);
        window.removeEventListener('testPassive', null, opts);
    } catch (e) {}
    return support;
}
