import debounce from '../utils/debounce';

class Faq {
    container: HTMLElement;
    question: HTMLElement | null;
    answer: HTMLElement | null;
    answerWrap: HTMLElement | null;
    scrollHeight: number;

    constructor(faq: HTMLElement) {
        this.container = faq;
        this.question = faq.querySelector('[data-faq-question]');
        this.answerWrap = faq.querySelector('[data-faq-answer-wrap]');
        this.answer = faq.querySelector('[data-faq-answer]');
        this.scrollHeight = this.answer?.scrollHeight || 0;

        this.init();
    }

    init() {
        this.answerWrap?.classList.add('hidden');
        this.question?.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggleAnswer();
        });

        window.addEventListener(
            'resize',
            debounce(() => this.updateScrollHeight(), 200),
        );
    }

    updateScrollHeight() {
        if (!this.answerWrap || !this.answer) return;

        this.scrollHeight = this.answer.scrollHeight;

        if (!this.answerWrap.className.includes('hidden')) {
            this.answerWrap.setAttribute(
                'style',
                `height: ${this.scrollHeight}px`,
            );
        }
    }

    sendTrackingInfo() {
        if (!window.digitalData) return;

        const mainSection = this.container.parentNode?.parentNode;
        const sectionTitle = mainSection?.querySelector<HTMLHeadingElement>(
            '.text-block__title',
        )?.innerText;
        const questionHeading = this.question
            ?.childNodes[1] as HTMLHeadingElement;
        const questionText = questionHeading?.innerText;

        const dataObj = {
            category: 'faq',
            action: sectionTitle,
            label: questionText,
        };

        window.digitalData.events = dataObj;

        try {
            window.Bootstrapper.ensEvent.trigger('faq-click');
        } catch (err) {
            //  Block of code to handle errors
            console.error(
                `Couldn't reach Bootstrapper to trigger 'faq-click' with ${JSON.stringify(
                    dataObj,
                )}`,
            );
        }
    }

    toggleAnswer() {
        if (!this.answerWrap || !this.question) return;

        if (this.answerWrap.className.includes('hidden')) {
            this.question.classList.add('open');
            this.answerWrap.setAttribute(
                'style',
                `height: ${this.scrollHeight}px`,
            );
            this.answerWrap.classList.remove('hidden');
            this.sendTrackingInfo();
        } else {
            this.question.classList.remove('open');
            this.answerWrap.setAttribute('style', '');
            this.answerWrap.classList.add('hidden');
        }
    }
}

export default function createFaqs() {
    const faqs = Array.from(document.querySelectorAll('[data-faq-item]'));

    faqs.forEach((faq) => new Faq(faq));
}
