/* eslint-disable no-param-reassign */
import debounce from '../utils/debounce';
import scrollTo from '../utils/smoothscroll';
import { isMobile } from '../constants/mediaqueries';

let prevWindowWidth: number;

function TextImage(element: HTMLElement) {
    const button = element.querySelector('.button-round') as HTMLElement;
    if (!button) return;
    const isLeftOriented = element.classList.contains('text-image--image-left');

    const rotateArrowButton = (scrollPosition: number) => {
        if (scrollPosition > window.innerWidth / 2) {
            button.classList.add('button-round--arrow--left');
        } else {
            button.classList.remove('button-round--arrow--left');
        }
    };
    const scrollToLeft = () => {
        scrollTo(element, 0, window.scrollY, false);
    };
    const scrollToRight = () => {
        scrollTo(element, element.offsetWidth, window.scrollY, false);
    };
    const handleClick = () => {
        if (element.scrollLeft === 0) {
            scrollToRight();
        } else {
            scrollToLeft();
        }
    };
    const scrollElementsToPosition = () => {
        if (!isMobile()) return;

        if (isLeftOriented) {
            scrollToRight();
        } else {
            scrollToLeft();
        }
    };
    const observe = () => {
        const config = {
            rootMargin: '0px 0px 0px 0px',
            threshold: 0.5,
        };
        const onIntersecting = (
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
        ) => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                const { isIntersecting, target } = entry;
                if (isIntersecting) {
                    element.classList.add('in-view');
                    scrollElementsToPosition();

                    observer.unobserve(target);
                }
            });
        };
        const intersectionObserver = new IntersectionObserver(
            onIntersecting,
            config,
        );
        intersectionObserver.observe(element);
    };

    const handleResize = () => {
        /* The resize event is executed either when the menubar disappears/appears or when the searchbar appears/disappears.
         * This check prevents executing the scrollElementsToPosition method which prevents the text image from scrolling.
         */
        if (prevWindowWidth !== window.innerWidth) {
            prevWindowWidth = window.innerWidth;
            scrollElementsToPosition();
        }
    };

    // listeners
    element.addEventListener(
        'scroll',
        debounce(() => rotateArrowButton(element.scrollLeft), 100),
    );
    button.addEventListener('click', () => handleClick());
    window.addEventListener('resize', debounce(handleResize, 200));

    // positioning
    if (!isLeftOriented) {
        element.scrollLeft = element.offsetWidth;
    }

    // add smooth scroll
    const supportsNativeSmoothScroll =
        'scrollBehavior' in document.documentElement.style;
    if (supportsNativeSmoothScroll) {
        element.style.scrollBehavior = 'smooth';
    }

    // start observing
    observe();
}

export default function textImageModules() {
    const elements = document.querySelectorAll<HTMLElement>('.text-image');
    elements.forEach((element) => TextImage(element));
}
