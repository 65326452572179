import './utils/polyfills';
import '../styles/style.scss';
import 'lazysizes';
import inView from './services/in-view';
import navigation from './services/navigation';
import slideInOut from './services/slide-in-out';
import formSubmit from './services/form-submit';
import createProductHeroes from './components/product-hero';
import featureDetection from './utils/featureDetection';
import createSliders from './components/slider';
import createCarousels from './components/carousel';
import debounce from './utils/debounce';
import loadParallax from './services/parallax';
import initCards from './components/card';
import notificationPage from './components/notification-page';
import forms from './components/form';
import createFaqs from './components/faq';
import history from './services/history';
import ageGateway from './services/age-gateway';
import promoBar from './services/promo-bar';
import socialShare from './components/social-share';
import countrySelector from './services/country-selector';
import getCountries from './services/country-list';
import notificationBar from './services/notification-bar';
import popUp from './services/pop-up';
import textImageModules from './components/text-image';
import raasButton from './components/raas-button';
import youtubeVideo from './components/youtube-video';

export interface State {
    hasNotificationBar: boolean;
}

/* The DOMContentLoaded event fires when the initial HTML document
 * has been completely loaded and parsed, without waiting for
 * stylesheets, images, and subframes to finish loading.
 */
function DomContentLoadedHandler() {
    featureDetection();
    inView.init(); // moved to DomContentLoaded to show content quicker since we're lazyloading images
    notificationBar();
    navigation.init();
    createCarousels();
    createSliders();
    createProductHeroes();
    loadParallax();
    ageGateway();
    initCards();
    notificationPage.init();
    forms();
    createFaqs();
    textImageModules();
    history();
}

/* The load event is fired when the whole page has loaded,
 * including all dependent resources such as stylesheets and images.
 */
function loadHandler() {
    getCountries();
    slideInOut.init();
    youtubeVideo();
    countrySelector();
    promoBar();
    formSubmit.init();
    socialShare();
    popUp();
    raasButton();
}

function scrollHandler() {
    navigation.toggleHeader();
    navigation.toggleOffset();
}

function resizeHandler() {
    slideInOut.handleResize();
}

export const STATE: State = {
    hasNotificationBar: false,
};

// Global listeners
document.addEventListener('DOMContentLoaded', () => DomContentLoadedHandler());
window.addEventListener('load', () => loadHandler());
window.addEventListener('scroll', debounce(scrollHandler, 200));
window.addEventListener('resize', debounce(resizeHandler, 200));
