/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { TweenMax, TimelineMax } from 'gsap'; // Also works with TweenLite and TimelineLite
import * as ScrollMagic from 'scrollmagic'; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { isDesktop } from '../constants/mediaqueries';
import debounce from '../utils/debounce';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

let controller: ScrollMagic.Controller | null;
let scene: ScrollMagic.Scene | null;
let tl: TimelineMax | null;

function createScrollMagic() {
    const images = document.querySelectorAll('[data-id="history-image"]');
    const text: NodeListOf<HTMLElement> = document.querySelectorAll(
        '[data-id="history-text"]',
    );
    const years: NodeListOf<HTMLElement> = document.querySelectorAll(
        '[data-id="history-year"]',
    );
    const yearWidth = years[0].offsetWidth;
    const offsetIn = yearWidth + window.innerHeight;
    const offsetOut = window.innerHeight;
    const duration = `${text.length * 100}%`;

    tl = new TimelineMax();
    // Create a timeline for every content block
    for (let i = 0; i < text.length; i++) {
        const firstSlide = i === 0;
        const lastSlide = i === text.length - 1;
        const prevIndex = i - 1;
        const title = text[i].querySelector('[data-id="title"]');
        const description = text[i].querySelector('[data-id="description"]');

        // slide out image of previous slide
        if (images[prevIndex]) {
            tl.add(
                TweenMax.to(images[prevIndex], 15, {
                    xPercent: 100,
                    delay: 0.1,
                }),
                `"${i}"`,
            );
        }

        // slide in image of current slide
        if (!firstSlide) {
            tl.add(TweenMax.from(images[i], 10, { xPercent: -100 }), `"${i}"`);
        }

        // slide in the date
        tl.add(
            TweenMax.from(years[i], 10, { y: -offsetIn, delay: 4 }),
            `"${i}"`,
        );

        // slide out the date
        tl.add(
            TweenMax.to(years[i], 10, {
                y: lastSlide ? 0 : offsetOut,
                delay: 20,
            }),
            `"${i}"`,
        );

        if (title && description) {
            // slide in text of current slide
            if (!firstSlide) {
                tl.add(
                    TweenMax.from(title, 10, { y: 100, opacity: 0 }),
                    `"${i}"`,
                );
                tl.add(
                    TweenMax.from(description, 10, {
                        y: 100,
                        opacity: 0,
                        delay: 1,
                    }),
                    `"${i}"`,
                );
            }

            // slide out text of current slide
            if (!lastSlide) {
                tl.add(
                    TweenMax.to(title, 10, { y: -100, opacity: 0, delay: 20 }),
                    `"${i}"`,
                );
                tl.add(
                    TweenMax.to(description, 10, {
                        y: -100,
                        opacity: 0,
                        delay: 21,
                    }),
                    `"${i}"`,
                );
            }
        }
    }

    // init controller
    controller = new ScrollMagic.Controller();

    // build scene
    scene = new ScrollMagic.Scene({
        triggerElement: '#pinMaster',
        triggerHook: 'onLeave',
        duration,
    })
        .setPin('#pinMaster')
        // @ts-ignore: Third party type is missing setTween type in Scene interface
        .setTween(tl)
        .addTo(controller);

    // add ready class to prevent ugly loading state
    document.getElementById('pinMaster')?.classList.add('ready');
}

function destroyScrollMagic() {
    if (controller) {
        controller.destroy(true);
    }

    if (scene) {
        scene.destroy(true);
    }

    if (tl) {
        tl.kill();
    }

    controller = null;
    scene = null;
    tl = null;
}

export default function history() {
    const container = document.getElementById('history');
    if (!container) return;

    if (isDesktop()) {
        createScrollMagic();
    }

    const scrollDownButton = document.querySelector('.arrow-down');
    const hero = document.querySelector<HTMLElement>('.history__hero');
    const clickHandler = () => {
        const top = hero?.offsetHeight || window.innerHeight;
        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    };
    let prevWindowWidth = window.innerWidth;
    const resizeHandler = () => {
        /* The resize event is executed either when the menubar disappears/appears or when the searchbar appears/disappears.
         * This check prevents executing the scrollElementsToPosition method which prevents the text image from scrolling.
         */
        if (prevWindowWidth !== window.innerWidth) {
            prevWindowWidth = window.innerWidth;
            destroyScrollMagic();
            if (isDesktop()) {
                createScrollMagic();
            }
        }
    };

    scrollDownButton?.addEventListener('click', clickHandler);
    window.addEventListener('resize', debounce(resizeHandler, 200));
}
