import { CarouselOptions } from './carousel';

class CarouselButtons {
    public buttonPrev: HTMLElement | null;
    public buttonNext: HTMLElement | null;

    constructor(carouselOptions: CarouselOptions) {
        const { containerEl } = carouselOptions;

        this.buttonPrev = containerEl.querySelector('[data-id="button-prev"]');
        this.buttonNext = containerEl.querySelector('[data-id="button-next"]');

        if (!this.buttonPrev || !this.buttonNext) return;
        // prev button
        this.buttonPrev.addEventListener('mouseenter', () =>
            this.buttonPrevMouseEnter(),
        );
        this.buttonPrev.addEventListener('mouseleave', () =>
            this.buttonPrevMouseLeave(),
        );
        this.buttonPrev.addEventListener('click', () =>
            this.buttonPrevMouseClick(),
        );
        // next button
        this.buttonNext.addEventListener('mouseenter', () =>
            this.buttonNextMouseEnter(),
        );
        this.buttonNext.addEventListener('mouseleave', () =>
            this.buttonNextMouseLeave(),
        );
        this.buttonNext.addEventListener('click', () =>
            this.buttonNextMouseClick(),
        );
    }

    public showNextButton(bool: boolean) {
        if (bool) {
            this.buttonNext?.classList.remove('hide');
        } else {
            this.buttonNext?.classList.add('hide');
        }
    }

    public showPrevButton(bool: boolean) {
        if (bool) {
            this.buttonPrev?.classList.remove('hide');
        } else {
            this.buttonPrev?.classList.add('hide');
        }
    }

    public buttonPrevMouseEnter() {
        this.buttonPrev?.removeAttribute('data-clicked');
        // call method from child class
    }

    public buttonPrevMouseLeave() {
        // to prevent ts error
        // call method from child class
    }

    public buttonPrevMouseClick() {
        this.buttonPrev?.setAttribute('data-clicked', 'true');
        // call method from child class
    }

    public buttonNextMouseEnter() {
        this.buttonNext?.removeAttribute('data-clicked');
        // call method from child class
    }

    public buttonNextMouseLeave() {
        // to prevent ts error
        // call method from child class
    }

    public buttonNextMouseClick() {
        this.buttonNext?.setAttribute('data-clicked', 'true');
        // call method from child class
    }
}

export default CarouselButtons;
