/* eslint-disable @typescript-eslint/no-explicit-any */
import isAutoplaySupported from '../utils/features/isAutoplaySupported';
import { isMobile } from '../constants/mediaqueries';
import { createPlayer } from './youtube-video';

const youtubeVideoBackground = (module: HTMLElement) => {
    if (isMobile()) return;
    const videoEl = module.querySelector<HTMLElement>('[data-video]');
    if (!videoEl) return;
    let player: any;
    const videoId = videoEl.id;
    if (!videoId) return;
    const videoIds = videoId.split(',');
    let currentVideoIndex = 0;
    const placeholder = module.querySelector('[data-video-placeholder]');
    const intersectionObserverConfig = {
        rootMargin: '0px 0px 0px 0px',
    };
    const title = module.querySelector('H1')?.innerHTML || '';
    const hide = () => placeholder?.classList.remove('hide');
    const show = () => placeholder?.classList.add('hide');

    const onIntersecting = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
            const { isIntersecting } = entry;
            if (isIntersecting) {
                player.playVideo();
            } else {
                player.pauseVideo();
            }
        });
    };

    const intersectionObserver = new IntersectionObserver(
        onIntersecting,
        intersectionObserverConfig,
    );

    const loadVideoById = (id: number) => {
        player.loadVideoById(videoIds[id]);
    };

    const onPlayerReady = () => {
        intersectionObserver.observe(module);
    };

    const onPlayerStateChange = (event: any) => {
        switch (event.data) {
            case window.YT.PlayerState.ENDED:
                hide();
                if (videoIds.length > 1) {
                    currentVideoIndex++;
                    if (currentVideoIndex < videoIds.length) {
                        loadVideoById(currentVideoIndex);
                    } else if (currentVideoIndex === videoIds.length) {
                        currentVideoIndex = 0;
                        loadVideoById(currentVideoIndex);
                    }
                }
                break;
            case window.YT.PlayerState.PLAYING:
                show();
                break;
            default:
                break;
        }
    };

    isAutoplaySupported((supported: boolean) => {
        if (supported) {
            const { youtubePlayer } = createPlayer({
                el: videoEl,
                videoId: videoIds[0],
                playlist: videoIds,
                background: true,
                onReady: onPlayerReady,
                onStateChange: onPlayerStateChange,
                title,
            });
            player = youtubePlayer;
        }
    });
};

export default youtubeVideoBackground;
