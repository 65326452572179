const notificationPage = {
    notFoundComponent: document.querySelector('[data-notification]'),
    init() {
        if (!this.notFoundComponent) return;
        const firstTitlePart = this.notFoundComponent.querySelector(
            '[data-notification-first-title]',
        );
        const secondTitlePart = this.notFoundComponent.querySelector(
            '[data-notification-second-title]',
        );
        const firstSubtitlePart = this.notFoundComponent.querySelector(
            '[data-notification-first-subtitle]',
        );
        const secondSubtitlePart = this.notFoundComponent.querySelector(
            '[data-notification-second-subtitle]',
        );
        const button = this.notFoundComponent.querySelector(
            '[data-notification-button]',
        );

        if (firstTitlePart) {
            this.animateInView(firstTitlePart);
            // After 500ms first part of title needs to go from 1 to 0.7
            setTimeout(
                () =>
                    firstTitlePart.setAttribute(
                        'style',
                        'transform: translate3d(0, 0, 0); opacity: 0.7',
                    ),
                500,
            );
        }

        if (secondTitlePart) {
            this.animateInView(secondTitlePart);
        }

        if (firstSubtitlePart) {
            this.animateInView(firstSubtitlePart);
        }

        if (secondSubtitlePart) {
            this.animateInView(secondSubtitlePart);
        }

        if (button) {
            this.animateInView(button);
        }
    },
    animateInView(element: Element) {
        element.setAttribute(
            'style',
            'transform: translate3d(0, 0, 0); opacity: 1',
        );
    },
};

export default notificationPage;
