export default function isTextSelected(input: HTMLInputElement) {
    const startPos = input.selectionStart || 0;
    const endPos = input.selectionEnd || 1000;

    const selObj = document.getSelection();
    const selectedText = selObj?.toString();

    if (selectedText?.length !== 0) {
        input.focus();
        input.setSelectionRange(startPos, endPos);
        return true;
    }
    if (input.value.substring(startPos, endPos).length !== 0) {
        input.focus();
        input.setSelectionRange(startPos, endPos);
        return true;
    }
    return false;
}
