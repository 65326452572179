/* eslint-disable @typescript-eslint/ban-ts-ignore, no-new */
import SimpleParallax from 'simple-parallax-js';

export default function loadParallax() {
    if (!window.IntersectionObserver) return;
    const images = document.querySelectorAll('[data-parallax="true"]');
    // eslint-disable-next-line
    const options: any = {
        orientation: 'down',
        scale: 1.15,
        overflow: false,
        delay: 0.2,
        transition: 'cubic-bezier(0,0,0,1)',
    };
    // @ts-ignore: Unreachable code error
    new SimpleParallax(images, options);
}
