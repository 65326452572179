import gsap from 'gsap';
import Xwiper from '../utils/xwiper';
import supportsPassive from '../utils/features/supportsPassive';
import isTouchDevice from '../utils/features/isTouchDevice';
import CarouselButtons from './carousel-buttons';
import { CarouselOptions } from './carousel';

type Preview = {
    previewSlide: Element;
    previewSize: number;
    slideSize: number;
    zIndex: number;
};

type Slide = {
    newSlide: Element;
    currentSlideBgImg: Element | null;
    currentSlideImg: Element | null;
    currentSlideLink: Element | null;
    currentSlideContent: Element | null;
    zIndex: number;
    onComplete: () => void;
};

class Slider extends CarouselButtons {
    private slider: HTMLElement;
    private slides: NodeListOf<Element> | null = null;
    private slidesLength = 0;
    private currentSlideIndex = 0;
    private transitionDuration = 0.7;
    private isAnimating = false;
    // pagination
    private paginationSlides: HTMLElement[] | null = null;
    // autoplay
    private autoplay = false;
    private autoplayInterval = 0;
    private rotationDuration = 4500;
    private progressInterval = 0;
    // panels
    private panelPrev: HTMLElement | null = null;
    private panelNext: Element | null = null;
    // previews
    private previewTransitionDuration = 0.4;
    private previewZIndex = 101; // to show on top of active slide(zIndex 100);
    private nextSlidePreviewVisible = false;
    private prevSlidePreviewVisible = false;
    private hideNextSlidePreviewTimeline: gsap.core.Timeline = gsap.timeline();
    private showNextSlidePreviewTimeline: gsap.core.Timeline = gsap.timeline();
    private hidePrevSlidePreviewTimeline: gsap.core.Timeline = gsap.timeline();
    private showPrevSlidePreviewTimeline: gsap.core.Timeline = gsap.timeline();

    constructor(carouselOptions: CarouselOptions) {
        super(carouselOptions);
        const { containerEl, autoplay } = carouselOptions;
        this.slider = containerEl;
        if (!this.slider) return;
        const list: HTMLElement | null = this.slider.querySelector(
            '[data-id="slider-list"]',
        );
        if (!list) return;
        const slides: NodeListOf<HTMLElement> | null = list.querySelectorAll(
            'li',
        );
        if (!slides) return;
        this.slides = slides;
        this.slidesLength = this.slides.length;
        this.autoplay = !!autoplay;
        this.paginationSlides = Array.from(
            this.slider.querySelectorAll('[data-id="pagination-slide"]'),
        );
        const panelPrev: HTMLElement | null = this.slider.querySelector(
            '[data-id="slider-button-prev"]',
        );
        if (panelPrev) {
            this.panelPrev = panelPrev;
        }
        const panelNext: HTMLElement | null = this.slider.querySelector(
            '[data-id="slider-button-next"]',
        );
        if (panelNext) {
            this.panelNext = panelNext;
        }

        this.init();
    }

    private hideContentOfAllSlides() {
        if (!this.slides) return;
        this.slides.forEach((slide) => {
            const content = Array.from(
                slide.querySelectorAll('[data-id="content-container"] > *'),
            );
            const currentSlideLink = slide.querySelector(
                '[data-id="slide-link"]',
            );
            content && gsap.set(content, { opacity: 0, x: -100 });
            currentSlideLink &&
                gsap.set(currentSlideLink, { opacity: 0, x: 100 });
        });
    }

    private getVisibilityInfo() {
        const firstSlideIndex = 0;
        const lastSlideIndex = this.slidesLength - 1;
        const isFirstSlide = this.currentSlideIndex <= firstSlideIndex;
        const isLastSlide = this.currentSlideIndex >= lastSlideIndex;

        return { isFirstSlide, isLastSlide };
    }

    public updateButtonsState(isFirstSlide: boolean, isLastSlide: boolean) {
        super.showPrevButton(!isFirstSlide);
        super.showNextButton(!isLastSlide);
    }

    private updatePanelButtonsState(
        isFirstSlide: boolean,
        isLastSlide: boolean,
    ) {
        if (isFirstSlide) {
            this.panelPrev?.classList.add('hide');
        } else {
            this.panelPrev?.classList.remove('hide');
        }

        if (isLastSlide) {
            this.panelNext?.classList.add('hide');
        } else {
            this.panelNext?.classList.remove('hide');
        }
    }

    private updateSlidesState() {
        if (!this.slides) return;
        this.slides.forEach((slide) => slide.classList.remove('is-selected'));
        this.slides[this.currentSlideIndex].classList.add('is-selected');
    }

    private updatePagingState() {
        if (!this.paginationSlides) return;
        this.paginationSlides.forEach((slide) => {
            slide.classList.remove('mouseenter');
            slide.classList.remove('is-selected');
        });
        const currentSlide = this.paginationSlides[this.currentSlideIndex];
        if (!currentSlide) return;
        currentSlide.classList.add('is-selected');
    }

    private updatePreviewState() {
        this.nextSlidePreviewVisible = false;
        this.prevSlidePreviewVisible = false;
    }

    private animateSlideContent() {
        if (!this.slides) return;
        this.hideContentOfAllSlides();
        const currentSlide = this.slides[this.currentSlideIndex];
        const contentContainer = currentSlide.querySelector(
            '[data-id="content-container"]',
        );
        const content = Array.from(
            currentSlide.querySelectorAll('[data-id="content-container"] > *'),
        );
        const currentSlideLink = currentSlide.querySelector(
            '[data-id="slide-link"]',
        );
        contentContainer && gsap.set(contentContainer, { opacity: 1 });
        content &&
            gsap.to(content, {
                stagger: 0.1,
                duration: 0.3,
                opacity: 1,
                x: 0,
                onComplete: () => {
                    currentSlideLink &&
                        gsap.to(currentSlideLink, {
                            duration: 0.3,
                            opacity: 1,
                            x: 0,
                        });
                },
            });
    }

    private selectSlide(index: number) {
        if (index >= 0 && index < this.slidesLength) {
            this.currentSlideIndex = index;
            const { isFirstSlide, isLastSlide } = this.getVisibilityInfo();
            this.updateButtonsState(isFirstSlide, isLastSlide);
            this.updatePanelButtonsState(isFirstSlide, isLastSlide);
            this.updateSlidesState();
            this.updatePagingState();
            this.updatePreviewState();
            // remove hover states from nav buttons
            this.buttonPrev?.classList.remove('mouseenter');
            this.buttonNext?.classList.remove('mouseenter');
            // enable preview toggle again
            this.isAnimating = false;
            this.animateSlideContent();
        }
    }

    private getPreview(previewSlideIndex: number) {
        if (!this.slides) return null;
        const previewSlide = this.slides[previewSlideIndex];
        if (!previewSlide) return null;
        const previewSize = window.innerWidth / 10;
        const slideSize = window.innerWidth;
        const zIndex = this.previewZIndex;

        return {
            previewSlide,
            previewSize,
            slideSize,
            zIndex,
        };
    }

    public buttonPrevMouseEnter() {
        super.buttonPrevMouseEnter();
        this.showPrevPreview();
        if (this.paginationSlides) {
            this.showPaginationSlide(
                this.paginationSlides[this.currentSlideIndex - 1],
            );
        }
    }

    public buttonPrevMouseLeave() {
        if (this.buttonPrev?.hasAttribute('data-clicked')) return;
        this.hidePrevPreview();
        if (this.paginationSlides) {
            this.hidePaginationSlide(
                this.paginationSlides[this.currentSlideIndex - 1],
            );
        }
    }

    public buttonNextMouseEnter() {
        super.buttonNextMouseEnter();
        this.showNextPreview();
        if (this.paginationSlides) {
            this.showPaginationSlide(
                this.paginationSlides[this.currentSlideIndex + 1],
            );
        }
    }

    public buttonNextMouseLeave() {
        if (this.buttonNext?.hasAttribute('data-clicked')) return;
        this.hideNextPreview();
        if (this.paginationSlides) {
            this.hidePaginationSlide(
                this.paginationSlides[this.currentSlideIndex + 1],
            );
        }
    }

    private showPrevPreview() {
        this.showSlidePreview(this.currentSlideIndex - 1, true);
    }

    private hidePrevPreview() {
        this.showSlidePreview(this.currentSlideIndex - 1, false);
    }

    private showNextPreview() {
        this.showSlidePreview(this.currentSlideIndex + 1, true);
    }

    private hideNextPreview() {
        this.showSlidePreview(this.currentSlideIndex + 1, false);
    }

    private showSlidePreview(previewSlideIndex: number, show: boolean) {
        if (previewSlideIndex === this.currentSlideIndex || this.isAnimating)
            return;
        const preview = this.getPreview(previewSlideIndex);
        if (!preview) return;
        if (previewSlideIndex < this.currentSlideIndex) {
            show
                ? this.showPrevSlidePreview(preview)
                : this.hidePrevSlidePreview(preview);
        } else {
            show
                ? this.showNextSlidePreview(preview)
                : this.hideNextSlidePreview(preview);
        }
    }

    private showNextSlidePreview(preview: Preview) {
        if (
            this.nextSlidePreviewVisible ||
            this.showNextSlidePreviewTimeline.isActive()
        )
            return;
        this.nextSlidePreviewVisible = true;
        const { previewSlide, previewSize, slideSize, zIndex } = preview;

        this.showNextSlidePreviewTimeline.set(previewSlide, {
            x: slideSize,
            zIndex,
        });
        this.showNextSlidePreviewTimeline.to(previewSlide, {
            x: slideSize - previewSize,
            duration: this.previewTransitionDuration,
        });
    }

    private hideNextSlidePreview(preview: Preview) {
        if (!this.nextSlidePreviewVisible) return;
        this.nextSlidePreviewVisible = false;
        const { previewSlide, previewSize, slideSize, zIndex } = preview;

        this.hideNextSlidePreviewTimeline.set(previewSlide, {
            x: slideSize - previewSize,
            zIndex,
        });
        this.hideNextSlidePreviewTimeline.to(previewSlide, {
            x: window.innerWidth,
            duration: this.previewTransitionDuration,
        });
    }

    private showPrevSlidePreview(preview: Preview) {
        if (
            this.prevSlidePreviewVisible ||
            this.showPrevSlidePreviewTimeline.isActive()
        )
            return;
        this.prevSlidePreviewVisible = true;
        const { previewSlide, previewSize, slideSize, zIndex } = preview;

        this.showPrevSlidePreviewTimeline.set(previewSlide, {
            x: -slideSize,
            zIndex,
        });
        this.showPrevSlidePreviewTimeline.to(previewSlide, {
            x: -slideSize + previewSize,
            duration: this.previewTransitionDuration,
        });
    }

    private hidePrevSlidePreview(preview: Preview) {
        if (!this.prevSlidePreviewVisible) return;
        this.prevSlidePreviewVisible = false;
        const { previewSlide, previewSize, slideSize, zIndex } = preview;

        this.hidePrevSlidePreviewTimeline.set(previewSlide, {
            x: -slideSize + previewSize,
            zIndex,
        });
        this.hidePrevSlidePreviewTimeline.to(previewSlide, {
            x: -slideSize,
            duration: this.previewTransitionDuration,
        });
    }

    private getNextSlide(slideIndex: number) {
        if (!this.slides) return null;
        const newSlide = this.slides[slideIndex];
        const currentSlide = this.slides[this.currentSlideIndex];
        const currentSlideBgImg = currentSlide.querySelector(
            '[data-id="slide-background-img"]',
        );
        const currentSlideImg = currentSlide.querySelector(
            '[data-id="slide-img"]',
        );
        const currentSlideLink = currentSlide.querySelector(
            '[data-id="slide-link"]',
        );
        const currentSlideContent = currentSlide.querySelector(
            '[data-id="content-container"]',
        );
        if (!newSlide) return null;
        const zIndex = this.previewZIndex;
        const onComplete = () => {
            gsap.set(newSlide, { clearProps: 'zIndex' });
            gsap.set(currentSlide, { x: window.innerWidth });
            currentSlideBgImg &&
                gsap.set(currentSlideBgImg, { clearProps: 'transform' });
            currentSlideImg &&
                gsap.set(currentSlideImg, {
                    clearProps: 'transform',
                    opacity: 1,
                });
            currentSlideLink &&
                gsap.set(currentSlideLink, { clearProps: 'transform' });
            currentSlideContent &&
                gsap.set(currentSlideContent, { clearProps: 'transform' });
            this.selectSlide(slideIndex);
        };

        return {
            newSlide,
            currentSlideBgImg,
            currentSlideImg,
            currentSlideLink,
            currentSlideContent,
            zIndex,
            onComplete,
        };
    }

    public buttonPrevMouseClick() {
        super.buttonPrevMouseClick();
        this.slideTo(this.currentSlideIndex - 1);
    }

    public buttonNextMouseClick() {
        super.buttonNextMouseClick();
        this.slideTo(this.currentSlideIndex + 1);
    }

    private slideTo(slideIndex: number, rotation = true) {
        if (slideIndex === this.currentSlideIndex) return;
        const slide = this.getNextSlide(slideIndex);
        if (!slide) return;
        const isNext = slideIndex > this.currentSlideIndex;

        if (this.supportsAutoplay()) {
            if (rotation) {
                this.startRotating();
            }

            // make sure current indicator reached 100%
            this.fillProgressCompletely();

            // reset indicators after latest slides
            if (slideIndex === 0) {
                this.clearAllProgressIndicators();
            }

            // start interval for slide
            this.startProgressInterval(slideIndex);
        }

        this.isAnimating = true;
        isNext ? this.slideToNext(slide) : this.slideToPrev(slide);
    }

    private slideToNext(slide: Slide) {
        const {
            newSlide,
            currentSlideBgImg,
            currentSlideImg,
            currentSlideLink,
            currentSlideContent,
            zIndex,
            onComplete,
        } = slide;
        gsap.set(newSlide, { x: window.innerWidth, zIndex });
        currentSlideBgImg &&
            gsap.to(currentSlideBgImg, {
                x: -(window.innerWidth / 5),
                duration: this.transitionDuration,
                ease: 'power2.out',
            });
        currentSlideImg &&
            gsap.to(currentSlideImg, {
                x: -(window.innerWidth / 5),
                opacity: 0,
                duration: this.transitionDuration,
                ease: 'power2.out',
            });
        currentSlideLink &&
            gsap.to(currentSlideLink, {
                x: -(window.innerWidth / 5),
                opacity: 0,
                duration: this.transitionDuration,
                ease: 'power2.out',
            });
        currentSlideContent &&
            gsap.to(currentSlideContent, {
                x: -(window.innerWidth / 5),
                opacity: 0,
                duration: this.transitionDuration,
                ease: 'power2.out',
            });
        gsap.to(newSlide, {
            x: 0,
            duration: this.transitionDuration,
            ease: 'power2.out',
            onComplete,
        });
    }

    private slideToPrev(slide: Slide) {
        const {
            newSlide,
            currentSlideBgImg,
            currentSlideImg,
            currentSlideLink,
            currentSlideContent,
            zIndex,
            onComplete,
        } = slide;
        gsap.set(newSlide, { x: -window.innerWidth, zIndex });
        currentSlideBgImg &&
            gsap.to(currentSlideBgImg, {
                x: window.innerWidth / 5,
                duration: this.transitionDuration,
                ease: 'power2.out',
            });
        currentSlideImg &&
            gsap.to(currentSlideImg, {
                x: window.innerWidth / 5,
                opacity: 0,
                duration: this.transitionDuration,
                ease: 'power2.out',
            });
        currentSlideLink &&
            gsap.to(currentSlideLink, {
                x: window.innerWidth / 5,
                opacity: 0,
                duration: this.transitionDuration,
                ease: 'power2.out',
            });
        currentSlideContent &&
            gsap.to(currentSlideContent, {
                x: window.innerWidth / 5,
                opacity: 0,
                duration: this.transitionDuration,
                ease: 'power2.out',
            });
        gsap.to(newSlide, {
            x: 0,
            duration: this.transitionDuration,
            ease: 'power2.out',
            onComplete,
        });
    }

    private showPrevButtonHover() {
        this.buttonPrev?.classList.add('mouseenter');
    }

    private hidePrevButtonHover() {
        this.buttonPrev?.classList.remove('mouseenter');
    }

    private showNextButtonHover() {
        this.buttonNext?.classList.add('mouseenter');
    }

    private hideNextButtonHover() {
        this.buttonNext?.classList.remove('mouseenter');
    }

    private showPaginationSlide(paginationSlide: Element) {
        paginationSlide.classList.add('mouseenter');
    }

    private hidePaginationSlide(paginationSlide: Element) {
        paginationSlide.classList.remove('mouseenter');
    }

    private paginationSlideEnter(
        e: Event,
        paginationSlide: HTMLElement,
        showSlidePreview: (e: Event, show: boolean) => void,
    ) {
        if (paginationSlide.classList.contains('mouseenter')) return;
        this.showPaginationSlide(paginationSlide);
        showSlidePreview(e, true);
        if (this.paginationSlides) {
            const index = this.paginationSlides.indexOf(paginationSlide);
            if (index > this.currentSlideIndex) {
                this.showNextButtonHover();
            } else if (index < this.currentSlideIndex) {
                this.showPrevButtonHover();
            }
        }
    }

    private paginationSlideLeave(
        e: Event,
        paginationSlide: HTMLElement,
        showSlidePreview: (e: Event, show: boolean) => void,
    ) {
        this.hidePaginationSlide(paginationSlide);
        showSlidePreview(e, false);
        if (this.paginationSlides) {
            const index = this.paginationSlides.indexOf(paginationSlide);
            if (index > this.currentSlideIndex) {
                this.hideNextButtonHover();
            } else if (index < this.currentSlideIndex) {
                this.hidePrevButtonHover();
            }
        }
    }

    private panelPrevEnter() {
        this.panelPrev?.removeAttribute('data-clicked');
        this.showPrevPreview();
        this.showPrevButtonHover();
        if (!this.paginationSlides) return;
        this.showPaginationSlide(
            this.paginationSlides[this.currentSlideIndex - 1],
        );
    }

    private panelPrevLeave() {
        if (this.panelPrev?.hasAttribute('data-clicked')) return;
        this.hidePrevPreview();
        this.hidePrevButtonHover();
        if (!this.paginationSlides) return;
        this.hidePaginationSlide(
            this.paginationSlides[this.currentSlideIndex - 1],
        );
    }

    private panelNextEnter() {
        this.panelNext?.removeAttribute('data-clicked');
        this.showNextPreview();
        this.showNextButtonHover();
        if (!this.paginationSlides) return;
        this.showPaginationSlide(
            this.paginationSlides[this.currentSlideIndex + 1],
        );
    }

    private panelNextLeave() {
        if (this.panelNext?.hasAttribute('data-clicked')) return;
        this.hideNextPreview();
        this.hideNextButtonHover();
        if (!this.paginationSlides) return;
        this.hidePaginationSlide(
            this.paginationSlides[this.currentSlideIndex + 1],
        );
    }

    private addPaginationSlideListeners(
        slidesArray: Element[],
        paginationSlide: HTMLElement,
    ) {
        const showSlidePreview = (e: Event, show: boolean) => {
            const el = e.currentTarget;
            if (el instanceof Element) {
                this.showSlidePreview(slidesArray.indexOf(el), show);
            }
        };
        paginationSlide.addEventListener('mouseenter', (e: Event) =>
            this.paginationSlideEnter(e, paginationSlide, showSlidePreview),
        );
        paginationSlide.addEventListener('mouseleave', (e: Event) =>
            this.paginationSlideLeave(e, paginationSlide, showSlidePreview),
        );
        paginationSlide.addEventListener('focus', (e: Event) =>
            this.paginationSlideEnter(e, paginationSlide, showSlidePreview),
        );
        paginationSlide.addEventListener('blur', (e: Event) =>
            this.paginationSlideLeave(e, paginationSlide, showSlidePreview),
        );
        paginationSlide.addEventListener('click', (e: Event) => {
            const el = e.currentTarget;
            if (el instanceof Element) {
                this.slideTo(slidesArray.indexOf(el));
                this.supportsAutoplay() && this.clearAllProgressIndicators();
            }
        });
    }

    private addListeners() {
        // pagination
        if (this.paginationSlides) {
            const slidesArray = Array.from(this.paginationSlides);
            this.paginationSlides.forEach((paginationSlide) =>
                this.addPaginationSlideListeners(slidesArray, paginationSlide),
            );
        }

        // panel buttons
        // prev
        this.panelPrev?.addEventListener('mouseenter', () =>
            this.panelPrevEnter(),
        );
        this.panelPrev?.addEventListener('mouseleave', () =>
            this.panelPrevLeave(),
        );
        this.panelPrev?.addEventListener('click', () => {
            this.panelPrev?.setAttribute('data-clicked', 'true');
            this.slideTo(this.currentSlideIndex - 1);
        });
        // next
        this.panelNext?.addEventListener('mouseenter', () =>
            this.panelNextEnter(),
        );
        this.panelNext?.addEventListener('mouseleave', () =>
            this.panelNextLeave(),
        );
        this.panelNext?.addEventListener('click', () => {
            this.panelNext?.setAttribute('data-clicked', 'true');
            this.slideTo(this.currentSlideIndex + 1);
        });

        if (isTouchDevice() && this.slidesLength > 1) {
            // touch gestures
            const xwiper = new Xwiper(
                this.slider,
                supportsPassive() ? { passive: true } : false,
            );
            xwiper.onSwipeLeft(() => this.slideTo(this.currentSlideIndex + 1));
            xwiper.onSwipeRight(() => this.slideTo(this.currentSlideIndex - 1));
        }
    }

    private supportsAutoplay() {
        // autoplay only if intersectionObserver is supported
        // to maintain high performance
        return (
            this.autoplay &&
            this.slidesLength > 1 &&
            window.IntersectionObserver
        );
    }

    private initRotating() {
        if (!this.supportsAutoplay()) return;

        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.startProgressInterval(this.currentSlideIndex);
                        this.startRotating();
                    } else {
                        this.clearProgressInterval();
                        this.stopRotating();
                    }
                });
            },
            {
                rootMargin: '0px 0px 0px 0px',
            },
        );

        // start rotating if slider is in viewport
        intersectionObserver.observe(this.slider);
    }

    private clearProgressInterval = () => {
        window.clearInterval(this.progressInterval);
    };

    private clearAllProgressIndicators() {
        if (!this.paginationSlides) return;
        this.paginationSlides.forEach((paginationSlide) => {
            const barIndicator = this.getProgressBarOfElement(paginationSlide);
            if (barIndicator) {
                barIndicator.style.transform = `scaleX(0)`;
            }
        });
    }

    private fillProgressCompletely() {
        if (!this.paginationSlides) return;
        const currentSlide = this.paginationSlides[this.currentSlideIndex];
        const barIndicator = this.getProgressBarOfElement(currentSlide);
        if (!barIndicator) return;
        this.scaleProgressBar(barIndicator, 1);
    }

    private getProgressBarOfElement(paginationSlide: HTMLElement) {
        const progressBar = paginationSlide.querySelector<HTMLSpanElement>(
            '[data-pagination-bar-indicator]',
        );
        return progressBar;
    }

    private scaleProgressBar(element: HTMLSpanElement, percentage: number) {
        // eslint-disable-next-line no-param-reassign
        element.style.transform = `scaleX(${percentage})`;
    }

    private startProgressInterval = (index: number) => {
        const interval = 20;
        let progress = 0;
        const setProgress = (i: number) => {
            progress = i;
        };
        if (!this.paginationSlides) return;
        const currentSlide = this.paginationSlides[index];
        const barIndicator = this.getProgressBarOfElement(currentSlide);
        if (!barIndicator) return;
        this.clearProgressInterval();
        this.progressInterval = window.setInterval(
            () =>
                this.progressTimer(
                    barIndicator,
                    interval,
                    progress,
                    setProgress,
                ),
            interval,
        );
    };

    private progressTimer = (
        barIndicator: HTMLSpanElement,
        interval: number,
        progress: number,
        setProgress: (i: number) => void,
    ) => {
        const percentage =
            Math.round((progress / this.rotationDuration) * 100) / 100;
        // scale progress bar
        this.scaleProgressBar(barIndicator, percentage);
        if (percentage >= 1) {
            this.clearProgressInterval();
            setProgress(0);
        } else {
            setProgress(progress + interval);
        }
    };

    private startRotating() {
        // remove focus of navigation items if starts rotating
        if (
            document.activeElement &&
            document.activeElement !== document.body &&
            document.activeElement instanceof HTMLElement
        ) {
            document.activeElement.blur();
        }

        // extra stop to make sure the existence of one single interval
        this.stopRotating();

        this.autoplayInterval = window.setInterval(
            this.rotationTimer,
            this.rotationDuration,
        );
    }

    private rotationTimer = () => {
        const hasNext = this.currentSlideIndex < this.slidesLength - 1;
        const nextIndex = hasNext ? this.currentSlideIndex + 1 : 0;
        this.slideTo(nextIndex, false);
    };

    private stopRotating() {
        window.clearInterval(this.autoplayInterval);
    }

    private init() {
        this.hideContentOfAllSlides();
        // make first slide active
        this.selectSlide(this.currentSlideIndex);
        this.addListeners();
        this.initRotating();
    }
}

export default function createSliders() {
    const sliders: HTMLElement[] = Array.from(
        document.querySelectorAll('[data-id="slider"]'),
    );
    sliders.forEach((slider: HTMLElement) => {
        const videos = slider.querySelectorAll('[data-video]');
        // eslint-disable-next-line no-new
        new Slider({
            containerEl: slider,
            autoplay: !videos,
        });
    });
}
