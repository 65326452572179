export default function countrySelector() {
    const body = document.getElementsByTagName('BODY')[0];
    const countrySelectorEl = document.getElementById('country-selector');
    const buttons = document.querySelectorAll(
        '[data-id="show-country-selector"]',
    );
    const closeButton = document.getElementById('close-country-selector');
    if (!countrySelectorEl || !buttons) return;
    const regions = countrySelectorEl.querySelectorAll(
        '[data-id="country-selector-regions"]',
    );

    const showCountrySelector = (e: Event) => {
        e.preventDefault();
        countrySelectorEl.classList.remove('country-selector--hidden');
        setTimeout(() => {
            countrySelectorEl.classList.add('country-selector--show');
            body.classList.add('lock-scroll');
        }, 50);
    };

    const hideCountrySelector = () => {
        countrySelectorEl.classList.remove('country-selector--show');
        body.classList.remove('lock-scroll');
        setTimeout(() => {
            countrySelectorEl.classList.add('country-selector--hidden');
        }, 300); // on transition end
    };

    buttons.forEach((button) =>
        button.addEventListener('click', showCountrySelector),
    );
    closeButton?.addEventListener('click', hideCountrySelector);
    regions.forEach((region) => {
        const button = region.querySelector('BUTTON');
        button?.addEventListener('click', () => {
            const className = 'country-selector__region--show';
            if (region.classList.contains(className)) {
                region.classList.remove(className);
            } else {
                region.classList.add(className);
            }
        });
    });
}
