/* eslint-disable no-explicit-any, @typescript-eslint/no-explicit-any */
const debounce = (callback: (...args: any[]) => any, wait = 250) => {
    let timer: NodeJS.Timer; // for browser probably should be `number | undefined`
    let lastCall = 0;
    return (...args: any[]) => {
        clearTimeout(timer);
        const now = Date.now();
        const timeFromLastCall = now - lastCall;

        if (timeFromLastCall > wait) {
            lastCall = now;
            callback(...args);
        } else {
            timer = setTimeout(() => {
                lastCall = now;
                callback(...args);
            }, wait);
        }
    };
};

export default debounce;
