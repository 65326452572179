/* eslint-disable @typescript-eslint/ban-ts-ignore, @typescript-eslint/no-explicit-any */

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

export default function forms() {
    const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
        '[data-form-input]',
    );
    const fileUploads: NodeListOf<HTMLInputElement> = document.querySelectorAll(
        '[data-file-upload]',
    );

    const shrinkLabel = (label: Element) => {
        label.classList.add('shrink');
    };

    const growLabel = (input: HTMLInputElement, label: Element) => {
        if (input.value === '') {
            label.classList.remove('shrink');
        }
    };

    const showFileNames = (event: any) => {
        const e: HTMLInputEvent = event;
        const { target } = e;
        if (!target) return;
        const { id } = target;
        const infoArea = !!id && document.querySelector(`[data-id=${id}]`);
        const { files } = target;
        if (infoArea) {
            infoArea.textContent = '';
        }
        files &&
            Array.from(files).forEach((file) => {
                const { name } = file;
                const el = document.createElement('P');
                el.textContent = name;
                if (infoArea) {
                    infoArea.append(el);
                }
            });
    };

    inputs.forEach((input: HTMLInputElement) => {
        const inputId = input.getAttribute('id');
        if (!inputId) return;

        const label = document.querySelector(`label[for="${inputId}"]`);
        if (!label) return;

        input.addEventListener('focus', () => shrinkLabel(label));
        input.addEventListener('blur', () => growLabel(input, label));
    });

    fileUploads.forEach((fileUpload) => {
        // @ts-ignore the right event
        fileUpload.addEventListener('change', showFileNames);
    });
}
